export async function errorHandler(context, e, defaultStatusCode) {
  try {
    const _id =
      context.store.getters.storeInformation?._id ||
      context.store.getters.storeInformation?.storeUrl ||
      'unknown-store';
    const message =
      [e.message || e.data?.message || e.data || e.statusText, _id, context.route?.path]
        .filter((e) => e)
        .join(', ')
        .trim() || 'unknown error';
    const statusCode = (e.response && e.response.status) || e.status || defaultStatusCode;

    let pageRequest = '';
    try {
      const routes = context.route.path.replace('/', '').split('/');
      const urlSlug = routes.length === 3 ? 'cat-facetgroup' : routes[0] || 'facets';
      const additionalSlug = routes.slice(1).join('/');
      pageRequest = [urlSlug, additionalSlug].filter((e) => e).join('/');
    } catch (e) {}
    if (statusCode === 404) {
      try {
        const { path } = context.route;
        const redirectUrl = path !== '/' ? (path || '').replace(/\/+/, '') : path;
        const { needRedirectToPage } = await context.$api.stores.getPageRedirectUrl(_id, `/${redirectUrl}`);
        if (needRedirectToPage) {
          return context.redirect(301, needRedirectToPage);
        }
      } catch (e) {}
    }
    console.log('errorHandler::-', message, statusCode);
    return context.error({ message, statusCode, pageRequest, statusText: e.response?.data?.message || '' });
  } catch (e) {
    return context.error({ message: `errorHandler::= ${e?.message || e?.data?.message || e}` });
  }
}
export const validateRequestPath = (slug) => {
  if (slug) {
    const link = decodeURI(slug);
    const path = link.split(`\?`)[0];
    if (path && path.match(/[^a-zA-Z0-9\/\-\.\_]/g)) {
      throw `Bad path ${slug}`;
    }
  }
};
